import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Configuration tab`}</h1>
    <p>{`Here the following configurations are available:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "990px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7c7c19346abbb359122d83c82daf7f1b/64889/configuration.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB6UlEQVQ4y6WTbW/aMBSF/f//yr50mlSxdhNtqbSt0zYGhZBQQsIqiLQ2ifPixHGSM/mCWwaCtZqlo9iO/fjce23W+eniLojgrh4wX/3GMkyxDBMso3RPqwO6DzmiTODNjQV22p/BWcXgcYwkjvA/7V3fAeuOPRrISoEnCWLOkaYp2hYbtUdVNy0SIdfAHzbYx1uXBqIoEEURwbIsQ1VVkFKSdN9od1yWEnEmALR4+90COx/cgT8+YDy2MBgMsFgswDlHnud0gO4LIUhZliKMOLJcPM3FMUecZECjcPJtBHYx9iASDs/3yZlepF2YthuidrIfdkNrT4cO2KXlrze+IOnP0L+bAXZuCehBipwKoh0eSv4h+LbDzkgDJx5UWYDzhAqiw92FNU2DoihIZVnSGj1n/qu6JuB7DbyyfbSVhKyq9Wl1vQfU1dS5VUrRfy0D1F/jcAP0gKZGUZZ0uglv1+FLQj63pmtgqyQqpWjj9smmX23cH2oG+GEyBes5PlQh6IUcK8jx12KADljP9oG2QZbnT7l5rUxRCHjt+GgqiccwPHiZ//2e1w67zhTsYjJHI0usggBBENBdfC3UAM+sCdjZaIZre47L8Qzd0RQ928Wn2YL0Wctd4MtGN/NnffW25aP/6x4nwyH+AK2DH2eqx4TIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c7c19346abbb359122d83c82daf7f1b/e93cc/configuration.webp 300w", "/static/7c7c19346abbb359122d83c82daf7f1b/b0544/configuration.webp 600w", "/static/7c7c19346abbb359122d83c82daf7f1b/653c7/configuration.webp 990w"],
              "sizes": "(max-width: 990px) 100vw, 990px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c7c19346abbb359122d83c82daf7f1b/eed55/configuration.png 300w", "/static/7c7c19346abbb359122d83c82daf7f1b/7491f/configuration.png 600w", "/static/7c7c19346abbb359122d83c82daf7f1b/64889/configuration.png 990w"],
              "sizes": "(max-width: 990px) 100vw, 990px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7c7c19346abbb359122d83c82daf7f1b/64889/configuration.png",
              "alt": "configuration-tab",
              "title": "configuration-tab",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2>{`General information`}</h2>
    <ul>
      <li parentName="ul">{`Team name;`}</li>
      <li parentName="ul">{`Operator status by default when logging in (active, pause);`}
        <ul parentName="li">
          <li parentName="ul">{`Active: operator could receive contacts;`}</li>
          <li parentName="ul">{`Pause: operator can’t receive any contact;`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If the operator can change status (Yes / No);`}</li>
      <li parentName="ul">{`If the operator is allowed to send files (Yes / No) via File Transfer.`}</li>
    </ul>
    <h2>{`Pauses (or breaks)`}</h2>
    <p>{`This feature allows you to define the types of breaks allowed for this team. It is very important to understand the time allocated to each type.`}</p>
    <h2>{`Video Broadcast (feature only available on voice channel)`}</h2>
    <p>{`Bitrate and resolution can be set in different combinations to yield different qualities of video. Bitrate refers to speed and file size, and resolution refers to width and height in pixels.`}</p>
    <h2>{`Assisted support navigation (or Cobrowsing)`}</h2>
    <p>{`This feature, the CoBrowsing, allows the operator to remotely access the visitor's device for support and help. You can define who controls the remote device and whether to hide certain fields to ensure the security and anonymization of the information.
It can be configured when the cobrowsing app is created and active.`}</p>
    <h2>{`Team chat (feature only available on CoreMedia Cloud Contact Center)`}</h2>
    <p>{`This feature allows you to have an internal chat channel for users of the CoreMedia platform. Some rules ensure that this kind of feature is only used for teams, between managers and supervisors/operators, and between supervisor and operator. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Note:
By default, operators can't chat between themselves 1 to 1 to ensure focus on contact management.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      